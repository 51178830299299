import Shake from "@shakebugs/browser";

import environments from "environments";

import { App, UserDataResponse, Workspace } from "models";

const startShakeSdk = () => {
  Shake.start(environments.shakeSDKApiKey, environments.shakeSDKBundleId);
  Shake.report.enableActivityHistory(true);
  Shake.report.isConsoleLogsEnabled = false;
  Shake.report.isCustomLogsEnabled = true;
  Shake.report.isScreenChangesEnabled = true;
  Shake.report.isSystemEventsEnabled = true;
  Shake.report.isNetworkRequestsEnabled = true;
  Shake.report.isUserActionsEnabled = true;
};

const registerShakeSdkUser = (userData: UserDataResponse) => {
  const nameArray: string[] = userData.name.split(" ");
  const firstName: string = nameArray?.[0];
  const lastName: string = nameArray?.[1];

  Shake.registerUser(userData.id);
  Shake.updateUserMetadata({ first_name: firstName, last_name: lastName, email: userData.email });
};

const unregisterShakeSdkUser = () => {
  Shake.unregisterUser();
};

const setShakeSdkAppMetadata = (app?: App) => {
  Shake.setMetadata("app_id", app?.id ?? "");
  Shake.setMetadata("app_key", app?.key ?? "");
  Shake.setMetadata("app_name", app?.name ?? "");
  Shake.setMetadata("app_bundle_id", app?.bundle_id ?? "");
  Shake.setMetadata("app_platform", app ? `${app.platform.os} ${app.platform.name}` : "");
};

const setShakeSdkWorkspaceMetadata = (workspace?: Workspace) => {
  Shake.setMetadata("workspace_id", workspace?.id ?? "");
  Shake.setMetadata("workspace_slug", workspace?.slug ?? "");
  Shake.setMetadata("workspace_name", workspace?.name ?? "");
};

export {
  startShakeSdk,
  registerShakeSdkUser,
  unregisterShakeSdkUser,
  setShakeSdkAppMetadata,
  setShakeSdkWorkspaceMetadata,
};
