const millisecondsToString = (ms: number): string => {
  let result = "";
  if (ms >= 0 && ms < 1000) {
    result = `${ms}ms`;
  } else if (ms >= 1000) {
    const seconds: number = ms / 1000;
    result = `${seconds}s`;
  }
  return result;
};

export { millisecondsToString };
