import { useCallback, useEffect, useState } from "react";

import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import env from "environments";

import { getIntegrationTypeName, SentryCallbackParams } from "models/integrations";
import {
  CommonPassthroughState,
  IntegrationType,
  OAuth2CallbackParams,
  OAuth2PassthroughState,
} from "models/integrations";

import { RoutePaths } from "router/config/routePaths";

import useWorkspaceDataApiConsumer from "../../../consumers/useWorkspaceDataApiConsumer";
import {
  extractCallbackParams,
  extractPassthroughState,
  extractStringifiedState,
  getHashParams,
  typeToIntegrationType,
} from "../../../layouts/util/extractingFunctions";
import { useIntegrationActivationDeps } from "../IntegrationActivation";
import { IntegrationActivationService } from "../services/IntegrationActivationService";

export const useIntegrationActivationConsumer = () => {
  const navigate = useNavigate();
  const { service } = useIntegrationActivationDeps();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { fetchWorkspaceDataAndDispatch } = useWorkspaceDataApiConsumer();

  const [searchParams] = useSearchParams();
  const integrationType = typeToIntegrationType(searchParams.get("type"));

  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(true);

  const activate = useCallback(
    async (type: IntegrationType) => {
      try {
        const callbackState = extractStringifiedState(searchParams, type);

        if (!callbackState && type === IntegrationType.SENTRY) {
          setLoading(false);
          setInvalid(false);
          return;
        }

        if (!callbackState) throw new Error("Missing state");

        const passthroughState = extractPassthroughState(callbackState, type);

        if (!passthroughState) throw new Error("Missing passthrough state info");

        const { workspaceSlug, appId, workspaceId } = passthroughState;

        await activateIntegrationByType(searchParams, service, type, passthroughState);

        let appKey;

        await fetchAllApps(workspaceId).then((allApps) => {
          appKey = allApps.find((app) => app.id === appId)?.key;
        });
        await fetchWorkspaceDataAndDispatch();

        if (integrationType === IntegrationType.SENTRY)
          navigate(
            generatePath(`${RoutePaths.ADMINISTRATION_EXTENSIONS}?type=${type}`, {
              workspaceSlug,
            }),
            {
              replace: true,
            },
          );
        else if (appKey)
          navigate(
            generatePath(`${RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK}?appId=${appId}&type=${type}`, {
              workspaceSlug,
              appKey: appKey,
            }),
            {
              replace: true,
            },
          );
        else
          navigate(
            generatePath(`${RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK}`, {
              workspaceSlug,
            }),
            {
              replace: true,
            },
          );

        displayToast({
          title: "Fantastic!",
          content: `${getIntegrationTypeName(integrationType)} successfully integrated.`,
        });
      } catch (error) {
        setLoading(false);
        setInvalid(true);
      }
    },
    [searchParams, integrationType, navigate, service, fetchAllApps],
  );

  useEffect(() => {
    if (!integrationType) {
      setLoading(false);
      setInvalid(true);
      return;
    }
    activate(integrationType);
  }, [integrationType, activate]);

  return { invalid, loading, setLoading, integrationType };
};

interface TrelloHashParams {
  token: string;
}

function activateIntegrationByType(
  params: URLSearchParams,
  service: IntegrationActivationService,
  type: IntegrationType,
  passthroughState: CommonPassthroughState,
) {
  const { workspaceId, appId } = passthroughState;

  switch (type) {
    case IntegrationType.CLICKUP: {
      const { code } = extractCallbackParams(params, type) as OAuth2CallbackParams;
      if (!code) throw new Error("Missing code");
      return service.activateClickupIntegration(workspaceId, type, appId, {
        authorization_code: code,
        client_id: env.clickupClientId,
      });
    }
    case IntegrationType.SENTRY: {
      const { code, installationId } = extractCallbackParams(params, type) as SentryCallbackParams;
      if (!code) throw new Error("Missing code");
      return service.activateSentryIntegration(workspaceId, type, {
        authorization_code: code,
        installation_id: installationId,
        client_id: env.sentryClientId,
      });
    }
    case IntegrationType.TRELLO:
      const hash = window.location.hash;
      const tokenObject = getHashParams<TrelloHashParams>(hash);

      if (!tokenObject.token) throw new Error("Missing token");

      return service.activateTrelloIntegration(workspaceId, type, appId, {
        api_key: env.trelloAppKey,
        api_token: tokenObject.token,
      });
    default: {
      const { code } = extractCallbackParams(params, type) as OAuth2CallbackParams;
      if (!code) throw new Error("Missing code");

      const { stateId } = passthroughState as OAuth2PassthroughState;
      if (!stateId) throw new Error("Missing state identifier");

      return service.activateIntegration(workspaceId, type, {
        authorization_code: code,
        state: stateId,
      });
    }
  }
}
