import React from "react";

import { FloatingOverlay } from "@floating-ui/react";

import { ReactComponent as Search } from "assets/images/smart-search-icon.svg";

import { useKeyboardPress } from "components/ShakeTable/useKeyboardPress";

import { vars } from "styles";

import { useAttributesContext } from "../context/AttributesProvider";
import * as Styled from "./Attributes.styles";
import { AttributeSelectorPopover } from "./AttributeSelectorPopover";
import { usePopover } from "./SimplePopover";

type Props = {
  label?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  tooltipPosition?: "bottom" | "top" | "right" | "left";
  searchField?: string;
  enableKeyboardShortcut: boolean;
};

type UIProps = {
  isTrailing?: boolean;
};

export function AddAttributePill({
  label,
  isTrailing,
  disabled,
  disabledTooltip,
  tooltipPosition,
  searchField,
  enableKeyboardShortcut,
}: Props & UIProps) {
  const { reference, floating, getFloatingProps, getReferenceProps, strategy, open, x, y, setOpen } = usePopover({});

  const { add } = useAttributesContext();

  const tooltipText =
    disabled && disabledTooltip ? disabledTooltip : label ? `Add another ${label}.` : "Add another filter.";

  useKeyboardPress({
    keyboardKey: "f",
    onPress: (e) => {
      if (!searchField) return;
      e.preventDefault();
      add(searchField);
      setOpen(true);
    },
    enabled: (Boolean(searchField) && enableKeyboardShortcut) ?? false,
    meta: true,
  });

  return (
    <>
      <Styled.PillContainer
        $borderLeftNone={isTrailing}
        $minWidth={!!label}
      >
        <Styled.AddFilterFlexButton
          $aspect={label ? undefined : 1}
          ref={reference}
          {...getReferenceProps()}
          disabled={disabled}
          $disabled={disabled}
          $borderLeft={!label}
        >
          {label && <Styled.AddFilter>{label === " filter" ? "Search" : `Add ${label}`}</Styled.AddFilter>}
          <Styled.Tooltip
            text={tooltipText}
            position={tooltipPosition ?? "bottom"}
            offset={2}
          >
            {label ? <Search /> : <Styled.Plus />}
          </Styled.Tooltip>
        </Styled.AddFilterFlexButton>

        {open && (
          <FloatingOverlay
            style={{
              zIndex: vars.zIndex.modalOverlay,
              overflow: "inherit",
            }}
          >
            <div
              ref={floating}
              {...getFloatingProps()}
              style={{
                top: y ?? 0,
                left: x ?? 0,
                width: "25rem",
                minHeight: "10rem",
                position: strategy,
                zIndex: vars.zIndex.modalContent,
                overflow: "inherit",
              }}
            >
              <AttributeSelectorPopover />
            </div>
          </FloatingOverlay>
        )}
      </Styled.PillContainer>
    </>
  );
}
