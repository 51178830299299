import React, { Fragment, useState } from "react";

import sentry from "assets/images/integrations/sentry-icon.svg";

import { DropdownItem } from "components/Dropdown/Dropdown";
import Icon from "components/Icon/Icon";

import { Sentry, SentryIntegrationInfo, SentryIntegrationIssue } from "models/integrations";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import { Workspace } from "../../../../models";
import { RoutePaths } from "../../../../router/config/routePaths";
import * as Styled from "./SentryIntegrationContainer.styles";
import { SentryIssueRow } from "./SentryIssueRow";

interface Props {
  sentryIntegration?: Sentry;
  sentryDataProps?: SentryIntegrationInfo[];
  fetchSentryIssues: (projectSlug: string, environment: string) => Promise<SentryIntegrationIssue[] | undefined>;
  selectedWorkspace?: Workspace;
}

export const SentryIntegrationContainer = ({
  sentryIntegration,
  sentryDataProps,
  fetchSentryIssues,
  selectedWorkspace,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [selectedSentry, setSelectedSentry] = useState<SentryIntegrationInfo | null>(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState<string>();
  const [sentryIssues, setSentryIssues] = useState<SentryIntegrationIssue[]>([]);
  const [isSentryProjectDropdownOpen, setIsSentryProjectDropdownOpen] = useState<boolean>(false);
  const [isSentryEnvironmentDropdownOpen, setIsSentryEnvironmentDropdownOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const selectEnvironment = async (environment: string) => {
    setSelectedEnvironment(environment);
    setIsSentryEnvironmentDropdownOpen(false);

    if (!selectedSentry) return;

    setSelectedEnvironment(environment);
    setLoading(true);
    const issues = await fetchSentryIssues(selectedSentry.slug, environment);
    setSentryIssues(issues || []);
    setLoading(false);
  };

  const selectSentryData = async (sentryData: SentryIntegrationInfo) => {
    setSelectedSentry(sentryData);
    setIsSentryProjectDropdownOpen(false);
    await selectEnvironment(sentryData.environments[0]);
  };

  const emptyState = (
    <Tooltip
      text={"Learn more"}
      position="top"
      offset={0}
    >
      <a
        style={{ textDecoration: "none" }}
        href={`${RoutePaths.ADMINISTRATION_EXTENSIONS.replace(
          ":workspaceSlug",
          selectedWorkspace?.slug || "",
        )}?type=Sentry`}
      >
        <Styled.Table $enabled={false}>
          <Styled.TableHeader
            $isBodyOpen={true}
            $enabled={false}
          >
            <Flex
              $gap={1}
              $alignItems="flex-start"
            >
              <Styled.Image src={sentry} />
              <Styled.Header>Related Sentry errors</Styled.Header>
              <Styled.Pill>New</Styled.Pill>
            </Flex>
          </Styled.TableHeader>
          <Styled.TableBody>
            <Styled.GreyBox />
          </Styled.TableBody>
        </Styled.Table>
      </a>
    </Tooltip>
  );

  if (!sentryIntegration) return emptyState;

  return (
    <Styled.Table $enabled={true}>
      <Styled.TableHeader
        $isBodyOpen={isOpen}
        $enabled={true}
      >
        <Flex
          $gap={1}
          $alignItems="flex-start"
        >
          <Styled.Image src={sentry} />
          <Styled.Header>Sentry errors</Styled.Header>
          <Styled.ProjectDropdown
            position="left"
            wide
            label={selectedSentry ? selectedSentry.name : "Select project"}
            isOpened={isSentryProjectDropdownOpen}
            setIsOpened={setIsSentryProjectDropdownOpen}
            buttonElement={
              <Fragment>
                <Icon
                  icon="caret-down"
                  size={10}
                />
              </Fragment>
            }
          >
            {sentryDataProps &&
              sentryDataProps.map((sentry) => (
                <DropdownItem
                  key={sentry.id}
                  onClick={() => selectSentryData(sentry)}
                >
                  {sentry.name}
                </DropdownItem>
              ))}
          </Styled.ProjectDropdown>
          {selectedEnvironment && (
            <Styled.ProjectDropdown
              position="left"
              wide
              label={selectedEnvironment ? selectedEnvironment : "Select environment"}
              isOpened={isSentryEnvironmentDropdownOpen}
              setIsOpened={setIsSentryEnvironmentDropdownOpen}
              buttonElement={
                <Fragment>
                  <Icon
                    icon="caret-down"
                    size={10}
                  />
                </Fragment>
              }
            >
              {selectedSentry?.environments.map((environment) => (
                <DropdownItem
                  key={environment}
                  onClick={() => selectEnvironment(environment)}
                >
                  {environment}
                </DropdownItem>
              ))}
            </Styled.ProjectDropdown>
          )}
        </Flex>
        <div
          style={{
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            icon={isOpen ? "caret-up" : "caret-down"}
            size={10}
          />
        </div>
      </Styled.TableHeader>
      {isOpen && (
        <Styled.TableBody>
          {loading || !selectedSentry ? (
            <Styled.GreyBox />
          ) : sentryIssues.length !== 0 ? (
            sentryIssues.map((issue) => (
              <SentryIssueRow
                issue={issue}
                key={issue.id}
              />
            ))
          ) : (
            <Styled.Description style={{ margin: "1.2rem 2rem" }}>No issues found</Styled.Description>
          )}
        </Styled.TableBody>
      )}
    </Styled.Table>
  );
};
