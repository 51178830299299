import { useMemo } from "react";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName, PlatformOs } from "models";

import { useUserFeedbackDeps } from "pages/UserFeedback/UserFeedback";

import {
  AllFieldNameUXOperators,
  Attribute,
  AttributeOperator,
  Filter,
  FilterHit,
  FilterHitDisplay,
  UseModuleAttributes,
} from "../sharedTypes";
import { useAppAttributes } from "../useAppAttributes";
import {
  attributeIconFor,
  findAssigneeFilterHits,
  getFieldValue,
  getMappedAssignees,
  getUXOperatorForFilter,
  getUXOperators,
  isRangeFilter,
  partialFilterFor,
} from "../utils";

const mainFieldNames = ["title", "assignee_id", "status", "priority", "tags", "unread"];

const sharedSecondaryFieldNames = [
  "os_version.full_version",
  "device",
  "current_view",
  "city",
  "country",
  "device_orientation",
  "locale",
  "sdk_version.full_version",
  "network_type",
];

const secondaryMobileFieldNames = [
  "app_version.full_version",
  "authentication",
  "low_power_mode",
  "tester_email",
  "nfc",
  "xcode_version",
];

const secondaryWebFieldNames = [
  "is_mobile",
  "cookies_enabled",
  "browser_name",
  "browser_version.full_version",
  "os_name",
  "cpu_arch",
];

const availableFieldNames = mainFieldNames
  .concat(sharedSecondaryFieldNames)
  .concat(secondaryMobileFieldNames)
  .concat(secondaryWebFieldNames);

export type FeedbackFieldName = typeof availableFieldNames[number];

const availableOperators = ["is", "is_not", "contains", "greater_than", "less_than"] as const;

export function useFeedbackAttributes(
  withoutSearchParams?: boolean,
  metadataSearchFields?: string[],
  customSearchFields?: { [key: string]: string }[],
): UseModuleAttributes {
  const { wsTeamMembers } = useAppContext();
  const { selectedApp } = useAppSelectionContext();
  const { userFeedbackService } = useUserFeedbackDeps();

  const { addAttribute, removeAttribute, updateAttribute, attributes } = useAppAttributes({
    storageKeyPrefix: "shakebugs.feedback_attributes",
    defaultAttributes: selectedApp?.is_sample
      ? []
      : [
          {
            field_name: "status",
            filter: {
              filter_type: "exclude",
              filter_value: "term",
              fieldValue: "Closed",
            },
          },
        ],
    withoutSearchParams: withoutSearchParams,
  });

  const validAttributesJSONString = useMemo(
    () => JSON.stringify(attributes.filter((attr) => isValidAttribute(attr))),
    [attributes],
  );

  const secondaryFieldNames =
    selectedApp?.platform.name === PlatformName.WEB
      ? sharedSecondaryFieldNames.concat(secondaryWebFieldNames)
      : sharedSecondaryFieldNames.concat(
          selectedApp?.platform.os === PlatformOs.IOS
            ? secondaryMobileFieldNames
            : secondaryMobileFieldNames.filter((a) => a !== "xcode_version"),
        );

  const groupedAvailableFieldNames = [
    {
      title: "User feedback data",
      values: mainFieldNames,
    },
    {
      title: "",
      values: secondaryFieldNames,
    },
  ];

  function getAvailableAttributes(
    current: Array<Attribute>,
    metadataFields?: string[],
    customFields?: { [key: string]: string }[],
  ) {
    let fieldNames = groupedAvailableFieldNames;

    if (metadataFields && Boolean(metadataFields.length)) {
      fieldNames = fieldNames.concat({
        title: "User feedback metadata",
        values: metadataFields,
      });
    }
    if (customFields && Boolean(customFields.length)) {
      fieldNames = fieldNames.concat({
        title: "User feedback custom fields",
        values: customFields.flatMap((i) => Object.keys(i)),
      });
    }
    return fieldNames.filter((field) => {
      // return current.map((e) => e.field_name).includes(name);
      return field;
    });
  }

  return {
    attributes: attributes.map((attr) => {
      return {
        name: attr.field_name,
      };
    }),
    availableAttributes: useMemo(
      () => getAvailableAttributes(attributes, metadataSearchFields, customSearchFields),
      // eslint-disable-next-line
      [attributes, metadataSearchFields, customSearchFields],
    ),
    validAttributes: useMemo(() => JSON.parse(validAttributesJSONString), [validAttributesJSONString]),
    isFilterUXValid: (atIndex) => isFilterUXValid(atIndex, attributes),
    searchBoxInitialOpen,
    shouldOpenSearchBox,
    showsHitsResults: (attributeName) => showsHitsResults(attributeName, metadataSearchFields, customSearchFields),
    showSearch: (attributeName?: string) => {
      return attributeName !== "unread";
    },
    fetchHitsFN: ({ deps, signal, attributeName, query }) => {
      const workspaceID = deps[0];
      const appID = deps[1];
      if (
        attributeName === "title" ||
        Boolean(metadataSearchFields && metadataSearchFields.find((a) => a === attributeName)) ||
        Boolean(customSearchFields && customSearchFields?.find((a) => a.hasOwnProperty(attributeName)))
      ) {
        return Promise.resolve([]);
      }

      if (attributeName !== "assignee_id") {
        return userFeedbackService
          .getUserFeedbackFiltersHits(workspaceID, appID, query, attributeName, signal)
          .then(({ data }) => data);
      }
      return userFeedbackService
        .getUserFeedbackFiltersHits(workspaceID, appID, "", attributeName, signal)
        .then(({ data }) => findAssigneeFilterHits(query, getMappedAssignees(data, attributeName, wsTeamMembers)));
    },
    add: (attributeName: string) => {
      addAttribute({
        field_name: attributeName,
        filter: undefined,
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    addExternal(attributeName: string, filterValue: string, attributeOperator: AttributeOperator) {
      addAttribute({
        field_name: attributeName,
        filter: { ...partialFilterFor("is"), fieldValue: filterValue },
        attributeOperator: attributeOperator ? attributeOperator : attributes.length === 0 ? undefined : "and",
      });
    },
    update: (identifier: number, value: string | undefined | null, selectedOperator: string) => {
      const current = attributes.find((_, index) => index === identifier);
      if (!current) return;

      const filter: Filter = { ...partialFilterFor(selectedOperator), fieldValue: value };

      const attr: Attribute = {
        attributeOperator: identifier == 0 ? undefined : "and",
        field_name: current.field_name,
        filter,
      };

      updateAttribute(identifier, attr);
    },
    remove: (atIndex: number) => {
      removeAttribute(atIndex);
    },
    filterSelectorInitialOpen: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;

      return (
        !attribute.filter?.fieldValue &&
        attribute.filter?.fieldValue !== null &&
        isFilterUXValid(identifier, attributes) &&
        identifier === attributes.length - 1
      );
    },
    getAttributeValuePresentation: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return "";
      return (
        (attribute.filter && getFieldValue(attribute.field_name, wsTeamMembers, attribute.filter.fieldValue)) ||
        " is missing value"
      );
    },
    getAttributeIcon: (attributeName: string) => {
      return attributeIconFor(attributeName, customSearchFields, selectedApp?.platform);
    },
    getAttributeValue: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter?.fieldValue;
    },
    getAvailableUXOperators: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];
      return getUXOperators(
        getAvailableFieldNameUXOperators(attribute.field_name, metadataSearchFields, customSearchFields),
      );
    },
    getSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute || !attribute.filter) return;
      return getUXOperatorForFilter(attribute.filter, attribute.field_name);
    },
    getPreSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter
        ? getUXOperatorForFilter(attribute.filter, attribute.field_name)
        : getUXOperators(
            getAvailableFieldNameUXOperators(attribute.field_name, metadataSearchFields, customSearchFields),
          )[0];
    },
    getPredefinedValuesForAttribute: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];

      return predefinedValuesForFieldName(attribute.field_name);
    },
    getAttributeOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.attributeOperator;
    },
    attributeHasDefinedFilter: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;
      return !!attribute.filter;
    },
    filterHitMapper,
    isEnterKeyEnabled: (attributeName: string) => {
      if (attributeName === "title") return true;
      if (Boolean(metadataSearchFields && metadataSearchFields.find((a) => a === attributeName))) return true;
      if (Boolean(customSearchFields && customSearchFields?.find((a) => a.hasOwnProperty(attributeName)))) return true;

      return false;
    },
  };
}

function getAvailableFieldNameUXOperators(
  fieldName: string,
  metadataSearchFields?: string[],
  customSearchFields?: { [key: string]: string }[],
) {
  const rangeOperators: Array<AllFieldNameUXOperators> = ["greater_than", "less_than"];
  const assigneeOperators: Array<AllFieldNameUXOperators> = ["is", "is_unknown"];
  const descriptionOperators: Array<AllFieldNameUXOperators> = ["contains"];
  const unreadOperators: Array<AllFieldNameUXOperators> = ["is"];
  const includeExcludeOperators: Array<AllFieldNameUXOperators> = ["is", "is_not"];

  switch (fieldName as FeedbackFieldName) {
    case "app_version.full_version":
    case "os_version.full_version":
    case "sdk_version.full_version":
      return Array.from(availableOperators.filter((op) => op !== "contains"));
    case "assignee_id":
      return Array.from(assigneeOperators);
    case "title":
      return Array.from(descriptionOperators);
    case "unread":
      return Array.from(unreadOperators);
    case "priority":
    case "status":
    case "low_power_mode":
    case "authentication":
    case "cookies_enabled":
    case "is_mobile":
    case "nfc":
    case "current_view":
    case "tags":
    case "device":
    case "tester_email":
    case "locale":
    case "city":
    case "country":
    case "device_orientation":
      return Array.from(includeExcludeOperators);
    default:
      if (
        Boolean(
          metadataSearchFields?.find((a) => a === fieldName) ||
            customSearchFields?.find((a) => a.hasOwnProperty(fieldName)),
        )
      )
        return Array.from(descriptionOperators);

      return Array.from(availableOperators.filter((op) => !rangeOperators.includes(op)));
  }
}

function isValidAttribute(attribute: Attribute) {
  if (!attribute.filter) return false;

  if (isRangeFilter(attribute.filter)) {
    return attribute.filter.fieldValue !== undefined;
  }

  const fieldName = attribute.field_name as FeedbackFieldName;

  if (fieldName == "assignee_id" && attribute.filter.fieldValue === null) return true;

  if (!attribute.filter.fieldValue) return false;

  return attribute.filter.fieldValue.length > 0;
}

function isFilterUXValid(identifier: number, attributes: Attribute[]) {
  const attribute = attributes.find((_, index) => index === identifier);
  if (!attribute) return false;

  if (!attribute.filter) return true;
  return isValidAttribute(attribute);
}

function searchBoxInitialOpen(attributeName: string, selectedOperator: string) {
  const fieldName = attributeName as FeedbackFieldName;
  return fieldName !== "assignee_id" || (fieldName === "assignee_id" && selectedOperator !== "is_unknown");
}

function shouldOpenSearchBox(selectedUXOperator: string) {
  if ((selectedUXOperator as AllFieldNameUXOperators) === "is_unknown") return false;
  return true;
}

function showsHitsResults(
  attributeName: string,
  metadataSearchFields?: string[],
  customSearchFields?: { [key: string]: string }[],
) {
  if ((attributeName as FeedbackFieldName) === "title" || (attributeName as FeedbackFieldName) === "unread")
    return false;
  if (metadataSearchFields && metadataSearchFields.find((a) => a === attributeName)) return false;

  if (Boolean(customSearchFields && customSearchFields?.find((a) => a.hasOwnProperty(attributeName)))) return false;

  return true;
}

export function filterHitMapper(fieldName: string, issueFilters: FilterHit[], filterValue?: string | null | undefined) {
  const displayFilters = [] as FilterHitDisplay[];
  if (fieldName === "assignee_id")
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: filter.name,
        isChecked: filter.value === filterValue,
      });
    });
  else {
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: fieldName === "unread" ? filter.name : filter.value,
        isChecked: filter.value === filterValue,
      });
    });
  }
  return displayFilters;
}

function predefinedValuesForFieldName(fieldName: string) {
  switch (fieldName) {
    case "unread":
      return [
        { name: "Read", value: "false" },
        { name: "Unread", value: "true" },
      ];
  }
  return [];
}
