import styled from "styled-components";

import ButtonComponent from "components/Button/Button";

export const AppDevelopmentContainer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #2c2c2e;
  padding: 3.2rem;
  border-radius: 1rem;
  max-width: 88rem;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const ReadMoreButton = styled(ButtonComponent)`
  align-self: center;
`;
