import React, { Fragment, useState } from "react";

import ReactJson from "react-json-view";

import Button from "components/Button/Button";

import { useChunkedString } from "hooks/useChunkedString";

import { NetworkRequest } from "models/eventTracking";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { millisecondsToString } from "util/time";

import { DrawerHeader } from "./LogDetails";
import { LineSeparator, NetworkRequestSelectButton } from "./LogDetails.styles";
import * as Styled from "./NetworkRequestDetails.styles";

enum NetworkRequestDetailSelection {
  REQUEST = "request",
  RESPONSE = "response",
}

export default function NetworkRequestDetails({ log, closeDrawer }: { log: NetworkRequest; closeDrawer: () => void }) {
  const [selection, setSelection] = useState<NetworkRequestDetailSelection>(NetworkRequestDetailSelection.RESPONSE);

  return (
    <div>
      <DrawerHeader
        close={closeDrawer}
        header={
          <div>
            <NetworkRequestSelectButton
              $selected={selection === NetworkRequestDetailSelection.REQUEST}
              onClick={() => setSelection(NetworkRequestDetailSelection.REQUEST)}
            >
              Request
            </NetworkRequestSelectButton>
            <NetworkRequestSelectButton
              $selected={selection === NetworkRequestDetailSelection.RESPONSE}
              onClick={() => setSelection(NetworkRequestDetailSelection.RESPONSE)}
            >
              Response
            </NetworkRequestSelectButton>
          </div>
        }
      />
      <LineSeparator />

      {selection === NetworkRequestDetailSelection.REQUEST && (
        <Styled.URL>
          <Styled.KeyWrap>URL</Styled.KeyWrap>
          <Styled.NoOverflow>{log.url}</Styled.NoOverflow>
          <Styled.KeyWrap>Method</Styled.KeyWrap>
          <Styled.NoOverflow>{log.method}</Styled.NoOverflow>
          <Styled.KeyWrap>Status Code</Styled.KeyWrap>
          <Styled.NoOverflow>
            {log.status_code && (
              <Flex
                $alignItems="center"
                $gap={0.4}
              >
                <Styled.Circle style={{ backgroundColor: getStatusColor(log.status_code) }} />
                {log.status_code}
              </Flex>
            )}
          </Styled.NoOverflow>
          {log.duration != null && log.duration >= 0 ? (
            <>
              <Styled.KeyWrap>Duration</Styled.KeyWrap>
              <Styled.NoOverflow>{millisecondsToString(log.duration)}</Styled.NoOverflow>
            </>
          ) : null}
        </Styled.URL>
      )}
      <Styled.PresentationFlex>
        <Headers
          headers={selection === NetworkRequestDetailSelection.REQUEST ? log.request_headers : log.response_headers}
        />
        <Body
          key={log.id}
          body={selection === NetworkRequestDetailSelection.REQUEST ? log.request_body : log.response_body}
        />
      </Styled.PresentationFlex>
    </div>
  );
}

const Headers = ({ headers }: { headers: Record<string, unknown> }) => {
  return <Styled.HeadersGrid>{headersPresentation(headers)}</Styled.HeadersGrid>;
};

const headersPresentation = (headers: Record<string, unknown>) => {
  return Object.entries(headers).map(([key, value], index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueString = (value as any).toString();
    return (
      <React.Fragment key={index}>
        <Styled.KeyWrap>{key}</Styled.KeyWrap>
        <Styled.NoOverflow>{valueString}</Styled.NoOverflow>
      </React.Fragment>
    );
  });
};

const Body = ({ body }: { body: string }) => {
  if (body === "") return <Fragment />;

  return <div>{TryPrettyJson(body)}</div>;
};

const TryPrettyJson = (body: string) => {
  const { display, loadMore, hasMore } = useChunkedString(body);
  try {
    const o = JSON.parse(body);

    if (o && typeof o === "object") {
      return (
        <Styled.JSONTreeWrap>
          <Styled.NoOverflow>
            <ReactJson
              src={o}
              enableClipboard
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed={1}
              quotesOnKeys={false}
              theme={{
                base00: "transparent", // Background
                base01: "transparent",
                base02: "none",
                base03: "transparent",
                base04: vars.colors.white,
                base05: "",
                base06: "",
                base07: vars.colors.grape, // Keys
                base08: "",
                base09: vars.colors.grey30, // Text values
                base0A: "",
                base0B: "",
                base0C: vars.colors.grape, // Number keys
                base0D: vars.colors.grey80,
                base0E: vars.colors.grey80,
                base0F: vars.colors.green, // Number values
              }}
              style={{ overflowWrap: "anywhere", fontFamily: "inherit" }}
            />
          </Styled.NoOverflow>
        </Styled.JSONTreeWrap>
      );
      return <Styled.Preformatted>{JSON.stringify(o, null, 2)}</Styled.Preformatted>;
    }

    if (!display) return <Fragment />;

    return (
      <Fragment>
        <Styled.NoOverflow>{display}</Styled.NoOverflow>
        {hasMore && (
          <Button
            styling="textual"
            onClick={loadMore}
          >
            Show More
          </Button>
        )}
      </Fragment>
    );
  } catch (e) {
    return (
      <Fragment>
        <Styled.NoOverflow>{display}</Styled.NoOverflow>
        {hasMore && (
          <Button
            styling="textual"
            onClick={loadMore}
          >
            Show More
          </Button>
        )}
      </Fragment>
    );
  }
};

export const getStatusColor = (statusCode: string) => {
  if (statusCode >= "200" && statusCode < "300") return vars.colors.green;
  if (statusCode >= "400" && statusCode < "600") return vars.colors.red;
  if (statusCode >= "300" && statusCode < "400") return vars.colors.jiraBlue;
  return vars.colors.red;
};
